.cx-callback {
  position: relative !important;
  right: auto !important;
}

.cx-hide-component {
  display: none;
}
.cx-button-group.cx-buttons-window-control > button {
  display: none !important;
}
.cx-button-group.cx-buttons-binary .cx-btn.cx-callback-cancel {
  display: none;
}
.cx-calendar.cx-theme-background .cx-button-group.cx-buttons-default {
  display: none;
}
.cx-number-confirm {
  display: none;
}
.cx-confirmation .cx-callback-done {
  display: none;
}
.cx-calendar-day-container.cx-active {
  background-color: #12c35c !important;
}
.cx-calendar .cx-active,
.cx-calendar .cx-tablinks:focus,
.cx-calendar .cx-time-content:focus {
  background-color: #12c35c !important;
}
.cx-calendar .cx-calendar-row span:hover,
.cx-calendar .cx-tablinks:hover,
.cx-calendar div.cx-calendar-day-container:hover {
  background-color: #12c35c;
}
.cx-calendar-day-container:hover {
  background-color: #ade5ac !important;
}
.cx-calendar-container,
.cx-calendar {
  width: 100%;
}
.cx-callback {
  max-width: 600px;
  width: 50%;
  margin: auto;
}

.cx-calendar > .cx-button-group.cx-buttons-default .cx-btn.cx-btn-default {
  background-color: #fdfdfd;
  border-color: #12c35c !important;
  color: #444a52;
}
.cx-form-inputs > table {
  display: none;
}
/*
  	Light Theme Color Overrides
  */
.cx-widget.cx-theme-light {
  background-color: #fdfdfd;
  scrollbar-face-color: #cccccc;
  /* WCAG: non-text contrast for UI components - primary button focus state */
  /* loader icon */
}
.cx-widget.cx-theme-light .cx-theme {
  background-color: #fdfdfd;
  color: #444a52;
  border-color: #dae1e8 !important;
}
.cx-widget.cx-theme-light .cx-overlay {
  background-color: #fdfdfd;
}
.cx-widget.cx-theme-light .cx-svg-icon-tone1 {
  fill: #12c35c;
}
.cx-widget.cx-theme-light .cx-svg-icon-tone2 {
  fill: #8c8c8c;
}
.cx-widget.cx-theme-light .cx-svg-icon-shadow1 {
  fill: #000000;
  opacity: 0.2;
}
.cx-widget.cx-theme-light .cx-svg-icon-shadow2 {
  fill: #000000;
  opacity: 0.1;
}
.cx-widget.cx-theme-light * {
  border-color: #dae1e8;
}
.cx-widget.cx-theme-light .cx-titlebar .cx-title {
  color: #444a52;
}
.cx-widget.cx-theme-light .cx-buttons-window-control .cx-svg-icon-tone1 {
  fill: #8795a6;
}
.cx-widget.cx-theme-light .cx-input-icon-overlay .cx-svg-icon-tone1 {
  fill: #98a7b8;
}
.cx-widget.cx-theme-light .cx-input-group-btn .cx-svg-icon-tone1 {
  fill: #fdfdfd;
}
.cx-widget.cx-theme-light label {
  color: #444a52;
}
.cx-widget.cx-theme-light a {
  color: #000000;
}
.cx-widget.cx-theme-light a:hover {
  color: #000000;
}
.cx-widget.cx-theme-light .cx-icon-alert-circle {
  color: #f53131;
}
.cx-widget.cx-theme-light .cx-footer,
.cx-widget.cx-theme-light .cx-branding {
  color: #66759b;
}
.cx-widget.cx-theme-light .cx-footer *,
.cx-widget.cx-theme-light .cx-branding * {
  fill: #66759b;
}
.cx-widget.cx-theme-light .cx-form-control.cx-error {
  border-color: #f53131;
}
.cx-widget.cx-theme-light .cx-form-control::placeholder {
  color: #6f7491;
}
.cx-widget.cx-theme-light .cx-form-control:-moz-placeholder {
  color: #6f7491;
}
.cx-widget.cx-theme-light .cx-form-control::-moz-placeholder {
  color: #6f7491;
}
.cx-widget.cx-theme-light .cx-form-control:-ms-input-placeholder {
  color: #6f7491;
}
.cx-widget.cx-theme-light .cx-form-control::-webkit-input-placeholder {
  color: #6f7491;
}
.cx-widget.cx-theme-light input:focus,
.cx-widget.cx-theme-light textarea:focus,
.cx-widget.cx-theme-light .cx-btn:focus,
.cx-widget.cx-theme-light .cx-button-group button:focus,
.cx-widget.cx-theme-light .cx-form-control:not(.cx-error):focus {
  border-color: #ade5ac !important;
}
.cx-widget.cx-theme-light input[type='text'],
.cx-widget.cx-theme-light input[type='email'],
.cx-widget.cx-theme-light input[type='tel'],
.cx-widget.cx-theme-light select,
.cx-widget.cx-theme-light textarea {
  background-color: #fdfdfd;
  color: #444a52;
  border-color: #94948a;
}
.cx-widget.cx-theme-light .cx-btn-default {
  background: none;
  border-color: #dae1e8 !important;
  color: #fdfdfd;
  background-color: #555d66;
}
.cx-widget.cx-theme-light .cx-btn.cx-disabled {
  background: #cccccc;
}
.cx-widget.cx-theme-light .cx-btn-primary {
  color: #fdfdfd;
  border-color: #12c35c !important;
  background: #12c35c;
  /* Old browsers */
  background: -moz-linear-gradient(top, #12c35c 0%, #12c35c 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #12c35c 0%, #12c35c 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #12c35c 0%, #12c35c 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='@bg_color_4', endColorstr='@bg_color_5', GradientType=0);
  /* IE6-9 */
  background-color: #12c35c;
  /* Old browsers */
}
.cx-widget.cx-theme-light .cx-button-group .cx-btn-primary:focus,
.cx-widget.cx-theme-light .cx-btn-primary:focus,
.cx-widget.cx-theme-light .cx-button-group .cx-btn-default:focus,
.cx-widget.cx-theme-light .cx-btn-default:focus {
  border-color: #12c35c !important;
}
.cx-widget.cx-theme-light .cx-ac-dropdown-menu {
  background: #fdfdfd;
  border-color: #dae1e8 !important;
}
.cx-widget.cx-theme-light .cx-ac-suggestion:hover,
.cx-widget.cx-theme-light .cx-ac-suggestion.cx-active {
  background-color: #ade5ac;
  color: #fdfdfd;
}
.cx-widget.cx-theme-light ::-webkit-scrollbar-thumb {
  background: #939399;
  /* WCAG: Non-text contrast */
}
.cx-widget.cx-theme-light .cx-smokescreen {
  background-color: #fdfdfd;
  opacity: 0.7;
}
.cx-widget.cx-theme-light .cx-dialog-container .cx-dialog {
  background-color: #fdfdfd;
  border-color: #dae1e8 !important;
}
.cx-widget.cx-theme-light .cx-theme-background {
  background-color: #fdfdfd;
}
.cx-widget.cx-theme-light .cx-theme-border {
  border: 1px solid #dae1e8 !important;
}
.cx-widget.cx-theme-light .cx-theme-text {
  color: #444a52;
}
.cx-widget.cx-theme-light .cx-theme-icon-interactive1 svg .cx-svg-icon-tone1 {
  fill: #5081e1;
}
.cx-widget.cx-theme-light .cx-theme-icon-positive svg .cx-svg-icon-tone1 {
  fill: #34a84f;
}
.cx-widget.cx-theme-light .cx-theme-icon-negative svg .cx-svg-icon-tone1 {
  fill: #f53131;
}
.cx-widget.cx-theme-light .cx-theme-icon-warning svg .cx-svg-icon-tone1 {
  fill: #d47f2f;
}
.cx-widget.cx-theme-light .cx-warning {
  color: #f53131 !important;
}
.cx-widget.cx-theme-light .cx-warning .cx-icon svg * {
  fill: #f53131 !important;
}
.cx-widget.cx-theme-light .cx-countdown {
  color: #444a52;
}
.cx-widget.cx-theme-light .cx-countdown .cx-icon svg * {
  fill: #444a52;
}
.cx-widget.cx-theme-light .cx-loading-screen .cx-loading-icon svg g g,
.cx-widget.cx-theme-light .cx-loading-container .cx-loading-icon svg g g {
  stroke: #2b74ff;
}
.cx-widget.cx-theme-light
  .cx-loading-screen
  .cx-loading-icon
  svg
  g:first-child
  g,
.cx-widget.cx-theme-light
  .cx-loading-container
  .cx-loading-icon
  svg
  g:first-child
  g {
  stroke: #72799d;
}
