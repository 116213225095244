@use 'base-styles/abstracts/spacing' as s;

html {
  -ms-overflow-style: scrollbar;
  font-size: 62.5%;
  overflow: auto;
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  margin-left: 0;
}

html,
app-root,
claims-app-root,
.app-root,
body {
  height: 100%;
  overflow: visible !important; // prevent cookie pro banner scroll to top
}

.full-page {
  min-height: 100%;
  //float: left; // removed for causing layout issues with footer in AO.
  width: 100%;
  overflow-x: hidden;
}

.trustpilot-widget {
  position: absolute;
  right: 0;
}

hr.divider-fat {
  border: none;
  border-bottom: 2px solid var(--separator--secondary);
}

ol.list {
  list-style-type: inherit;
}

.btn {
  min-width: 256px;
}

.claims-heading,
h1,
h2,
h3,
h4,
h5,
h6 {
  @include s.margin-bottom(1);
}

footer {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: inherit;
  }
}

.form-group label,
.form-group .label {
  margin-bottom: 0;
}

input:focus {
  outline: none;
}

.list {
  padding-left: 20px;

  .list-item {
    margin-bottom: 15px;
    line-height: normal;

    &--unordered {
      list-style: none;
      position: relative;
      &:before {
        content: '\2022';
        display: block;
        position: absolute;
        max-width: 0;
        max-height: 0;
        left: -21px;
        top: -12px;
        font-size: 30px;
      }
    }
    &--primary:before {
      color: var(--text-color--highlight);
    }
  }
}

.icon {
  display: inline-block;
  width: 100%;
  height: 100%;
}

.rebrand {
  .claims-heading {
    color: var(--header--contrast);
    @include s.margin-bottom(1);
  }
}
